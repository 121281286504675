
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Order } from '@/types';
import { isEmpty } from 'lodash';

@Component
export default class OrdersListStatus extends Vue {
  @Prop({ type: Object, required: true }) readonly order!: Order;

  get isCanceled() {
    return !isEmpty(this.order.deleted_at);
  }

  get variant() {
    if (this.isCanceled || this.order.is_unfinished) {
      return 'danger';
    } else {
      const { slug } = this.order.status;

      if (slug === 'new') {
        return 'warning';
      }
      if (slug === 'confirm') {
        return 'info';
      }
      if (this.isCanceled) {
        return 'danger';
      }
      if (slug === 'for_delivery') {
        return 'success';
      }
      if (slug === 'delivered') {
        return 'success';
      }
    }

    return 'secondary';
  }

  get title() {
    const statusSlug = this.order.status?.slug;
    const methodSlug = this.order.send_method?.slug;

    if (this.isCanceled) {
      return 'Ακυρωμένη';
    } else if (this.order.is_unfinished) {
      return 'Ανολοκλήρωτη';
    } else if (statusSlug === 'for_delivery' && methodSlug == 'store_pickup') {
      return 'Έτοιμη για παραλαβή';
    } else {
      return this.order.status?.title;
    }
  }
}
