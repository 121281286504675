
import { Component, Vue, Prop } from 'vue-property-decorator';
import { isEmpty } from 'lodash';
import VueBarcode from 'vue-barcode';
import { CheckoutMethod, Order, Note, SendMethod, CartItem } from '@/types';
import OrdersDetails from '../components/OrdersDetails.vue';
import ProductThumbnail from '@/components/ProductThumbnail.vue';

@Component({
  components: {
    ProductThumbnail,
    OrdersDetails,
    VueBarcode,
  },
})
export default class OrdersPrint extends Vue {
  @Prop({ type: Object, required: true }) readonly order!: Order;
  @Prop({ type: Array, required: true })
  readonly checkoutMethods!: CheckoutMethod[];
  @Prop({ type: Array, required: true })
  readonly sendMethods!: SendMethod[];
  @Prop({ type: Array, required: true }) readonly adminNotes!: Note[];
  @Prop({ type: Number, required: false }) readonly checkout_charge!: number;
  @Prop({ type: Number, required: false }) readonly send_charge!: number;
  @Prop({ type: Number, required: false }) readonly coupon_value!: number;
  @Prop({ type: String, required: false }) readonly coupon_type!: string;
  @Prop({ type: Number, required: false }) readonly gift_cost!: number;

  fields = [
    { label: '#', key: '#', class: 'text-center' },
    { label: 'Φωτό', key: 'photo', class: 'text-center' },
    { label: 'Προιόν', key: 'title' },
    { label: 'Stock', key: 'stock', class: 'text-center' },
    { label: 'Barcodes', key: 'barcodes', class: 'text-center' },
    { label: 'Π.Λ Τιμή (€)', key: 'sell_price', class: 'text-center' },
    { label: 'Έκπτωση (%)', key: 'discount', class: 'text-center' },
    {
      label: 'Τελική τιμή (€)',
      key: 'final_price',
      class: 'text-center',
    },
    {
      label: 'Ποσότητα',
      key: 'quantity',
      class: 'font-weight-bold text-center',
    },
    {
      label: 'Σύνολο (€)',
      key: 'total_price',
      class: 'font-weight-bold text-center',
    },
  ];

  get status() {
    return this.order.statuses.find(
      status => status.id === this.order.status_id,
    );
  }

  get finalPriceTotal() {
    return this.order.products.reduce(
      (acc: number, { count, item }: CartItem) => {
        return acc + item.final_price * count;
      },
      0,
    );
  }

  get checkoutMethod() {
    return (
      this.checkoutMethods.find(
        (method: CheckoutMethod) => method.id === this.order.checkout_method_id,
      ) ?? null
    );
  }

  get hasCheckoutMethod() {
    return !!this.checkoutMethod;
  }

  get checkoutSendCharge() {
    return this.send_charge ?? 0;
  }

  get checkoutCharge() {
    return this.checkout_charge ?? 0;
  }

  get couponDiscount() {
    if (this.coupon_type === 'amount') {
      return this.coupon_value ?? 0;
    } else if (this.coupon_type === 'percentage') {
      return this.finalPriceTotal * (this.coupon_value / 100) ?? 0;
    } else {
      return 0;
    }
  }

  get giftCost() {
    return this.gift_cost ?? 0;
  }

  get sendMethod() {
    return (
      this.sendMethods.find(
        (method: SendMethod) => method.id === this.order.send_method_id,
      ) ?? null
    );
  }

  get hasSendMethod() {
    return !!this.sendMethod;
  }

  get hasCheckoutSendMethods() {
    return this.hasCheckoutMethod || this.hasSendMethod;
  }

  get hasInvoice() {
    return !isEmpty(this.order.invoice);
  }

  get invoiceType() {
    return this.hasInvoice ? 'Τιμολόγιο' : 'Απόδειξη';
  }

  get hasTelephone() {
    return !isEmpty(this.order.charge_address.telephone);
  }

  get hasDifferentSendAddress() {
    return !isEmpty(this.order.send_address);
  }

  get hasSendTelephone() {
    if (this.hasDifferentSendAddress) {
      return !isEmpty(this.order.send_address?.telephone);
    }

    return false;
  }

  get hasProducts() {
    return !isEmpty(this.order.products);
  }

  get hasNotes() {
    return !isEmpty(this.order.notes);
  }

  get hasCourierNotes() {
    return !isEmpty(this.order.courier_notes);
  }

  get hasAdminNotes() {
    return !isEmpty(this.adminNotes);
  }
}
