
import { Component, Prop, Vue } from 'vue-property-decorator';
import { omit } from 'lodash';
import qs from 'qs';
import request from '@/utils/request';
import QueryParamsPaginationMixin from '@/mixins/QueryParamsPaginationMixin';
import CustomersMethodsMixin from './mixins/CustomersMethodsMixin';
import ListPagination from '@/components/ListPagination.vue';
import ListThreeDotsMenu from '@/components/ListThreeDotsMenu.vue';
import ListActionsBar from '@/components/ListActionsBar.vue';
import Layout from './components/CustomersLayout.vue';

@Component({
  mixins: [QueryParamsPaginationMixin, CustomersMethodsMixin],

  components: {
    Layout,
    ListThreeDotsMenu,
    ListPagination,
    ListActionsBar,
  },
})
export default class CustomersAll extends Vue {
  @Prop({ type: Object, required: false }) readonly counters!: Object;
  @Prop({ type: Boolean, required: false }) readonly isCountersLoaded!: boolean;

  typeVariant(type: string) {
    return type === 'profile' ? 'success' : 'warning';
  }

  sortBy = 'fullName';
  sortDesc = false;
  fields = [
    { label: '', key: 'checkbox', class: 'text-right' },
    '#',
    { label: 'Όνομα', key: 'fullName', sortable: true },
    { label: 'Τύπος', key: 'customerType' },
    {
      key: 'email',
    },
    { label: '', key: 'actions', class: 'text-right' },
  ];
  breadcrumbItems = [
    {
      text: 'Αρχική',
      to: { name: 'home' },
    },
    {
      text: 'Πελάτες',
      active: true,
    },
  ];
  filters = [
    {
      label: 'Με Profile',
      apiName: 'has_profile',
      type: 'boolean',
      value: false,
    },
  ];
  isLoading = false;
  isLoaded = false;
  total = 0;
  items = [];
  searchTerm = '';
  selectedIds = [];

  get currentPage() {
    return Number(this.$route.query.page);
  }

  get numberPerPage() {
    return Number(this.$route.query.perPage);
  }

  get getFilters() {
    return {
      ...omit(this.$route.query, ['page', 'perPage']),
      filter: this.searchTerm,
    };
  }

  async customersProvider(ctx: any) {
    try {
      this.isLoading = true;

      const { data } = await request.get('/customers', {
        params: {
          page: this.$route.query.page,
          size: this.$route.query.perPage,
          sortBy: this.sortBy,
          sortDesc: this.sortDesc,
          ...ctx.filter,
        },
        paramsSerializer: params => {
          return qs.stringify(params, {
            arrayFormat: 'comma',
            encode: false,
          });
        },
      });

      this.total = data.customers.total;
      this.items = data.customers.data;
      this.isLoaded = true;
      return this.items;
    } catch (err) {
      this.$router.push({ name: 'error' });
      this.$swal({
        title: 'Σφάλμα',
        text: 'Δεv μπόρεσαν να φορτωθούν οι πελάτες',
        icon: 'error',
        timer: 3000,
      });
    } finally {
      this.isLoading = false;
    }
  }
}
